import { Theme, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCreateMultiContractRFQData } from "../../redux/selectors";
import { CreateMultiContractSummaryItem } from "./create-multi-contract-summary-item";
import {
  CreateMultiContractState,
  GroupedCreateMultiContractData,
  SupplierKeyValue,
} from "./create-multi-contract-table.types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RFQStatus } from "../../redux";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { height: "100%" },
    text: {
      borderBottom: `solid 1px ${theme.palette.divider}`,
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2.5),
    },
    notificationSent: {
      display: "flex",
      alignItems: "center",
    },
    notificationIcon: {
      color: theme.palette.success.main,
    },
    notificationSentText: {
      marginLeft: "10px",
    },
  })
);

type Props = {
  groupedMultiContractData: GroupedCreateMultiContractData[];
  suppliersOptions?: SupplierKeyValue[];
  errorsCount: number;
  actualRfqPk?: number;
};

export const CreateMultiContractSummary: React.FC<Props> = ({
  groupedMultiContractData,
  suppliersOptions,
  errorsCount,
  actualRfqPk,
}) => {
  const { t } = useTranslation();
  const createMultiContractRFQData =
    useSelector(selectCreateMultiContractRFQData) || [];
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.text}>
        {createMultiContractRFQData?.length > 0 ? (
          <div className={classes.notificationSent}>
            <CheckCircleIcon className={classes.notificationIcon} />

            <div className={classes.notificationSentText}>
              {t("project:multiContract:notificationSent")}
            </div>
          </div>
        ) : (
          <Trans
            i18nKey="project:multiContract:summaryDescription"
            components={[<b />]}
          />
        )}
      </Typography>

      <div>
        {groupedMultiContractData?.map((item, index) => {
          const supplier = suppliersOptions?.find(
            (supp) => supp?.value === item?.supplier_id
          );

          const rfq = createMultiContractRFQData.find((rfq) => {
            if (item.supplier_id === CreateMultiContractState.CANCEL) {
              return rfq.status === RFQStatus.CANCELED;
            } else if (
              item.supplier_id === CreateMultiContractState.LEAVE_IN_RFQ
            ) {
              return rfq.pk === actualRfqPk;
            } else {
              return rfq.winner.company_pk === supplier?.value;
            }
          });

          return (
            <CreateMultiContractSummaryItem
              key={`${item?.supplier_id}-${index}`}
              supplier={supplier}
              partsCount={item?.parts?.length}
              rfq={rfq}
            />
          );
        })}

        {errorsCount > 0 && (
          <CreateMultiContractSummaryItem
            key="errors"
            supplier={{
              value: CreateMultiContractState.ERROR,
              label: "project:multiContract:errors",
            }}
            partsCount={errorsCount}
          />
        )}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, FormControl, SelectChangeEvent, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { modalsActions } from "@next/redux/modalsSlices";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormFooter } from "../../form-footer/form-footer";
import { FormTitle } from "../../form-title/form-title";
import { FormAddButton } from "../../form-add-button/form-add-button";
import { FormParentBox } from "../../form-parent-box";
import { ScopeFormTable } from "../../table/scope-form-table/scope-form-table";
import { AddSpecListModal } from "../../modals/add-spec-list-modal/add-spec-list-modal";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { SelectSpecType } from "../../select-spec-type/select-spec-type";
import { SearchTextField } from "../../search-text-field/search-text-field";
import { TableLinesBox } from "../../table-lines-box/table-lines-box";
import {
  selectFormData,
  selectIsAllStepsCompleted,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectQuestionnaireName,
  selectSpecsOfScope,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  A2psProcessControlFormData,
  FieldMandatoryText,
  PaginationSizeForFormTable,
  PartialStep,
  PomDistributorFormKeyMapping,
  PosEndUsersFormKeyMapping,
  Questionnaire,
  QuestionnaireForms,
  ScopeFormData,
  SpecificationTableRow,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { showIncompleteStepsMessageForScopeForm } from "@next/modules/vendor-management/utils/showIncompleteStepsMessageForScopeForm";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import * as S from "./scope-form.styled";

const tableBottomText =
  "Thanks to declare if any qualified specifications are missing from the list above, " +
  "click 'Add Spec' and search through the complete list to include them.(CP / Not controlled process / NDT, Test ...).";
const tableTopTextForPosEndUserAndPomManufacturer =
  "As per AIRBUS DATABASES, you are qualified as per following AIRBUS CANADA Technical specifications. Could you please confirm if this perimeter is still accurate ? If Lines not needed anymore thanks to delete the line.  In case of existing limitation or limitation to be declared : please add it into the additional comment section precising the A2PS description.";
const tableTopTextForPomDistributor =
  "Declare couples ordered by the distributor  : [Spec + Manufacturer Name + Manufacturer Site]";

export const ScopeForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isTableInPomDistributorQuestionnaire =
    questionnaireName === Questionnaire.PomDistributor;
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const scopeFormData = useSelector(selectFormData) as ScopeFormData &
    A2psProcessControlFormData;
  const [page, setPage] = useState(1);
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const [isShowTableError, setIsShowTableError] = useState(false);
  const [specType, setSpecType] = useState<string | string[]>("");
  const [searchValue, setSearchValue] = useState("");
  const [hideCompletedTableLines, setHideCompletedTableLines] = useState(false);
  const { control, watch } = useForm<ScopeFormData>({
    defaultValues: scopeFormData,
  });

  useEffect(() => {
    if (scopeFormData) {
      dispatch(
        vendorManagementActions.fetchSpecsOfScopeRequest({
          companyId,
          search: searchValue,
          specType: specType,
          pagination: { page: page, pageSize: PaginationSizeForFormTable },
          isInPomDistributor: isTableInPomDistributorQuestionnaire,
          stepId: step.step,
          filter_completed: hideCompletedTableLines,
        })
      );
    }
  }, [page, searchValue, specType, hideCompletedTableLines]);

  const handleSpecTypeChange = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const value = event.target.value;

    setSpecType(value);
  };

  const clearSearch = () => {
    setSearchValue("");
  };

  const onClickOpenAddSpecListModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.ADD_SPEC_LIST_MODAL,
      })
    );
  };

  const handleAddSpecToScope = (row: SpecificationTableRow) => {
    dispatch(
      vendorManagementActions.addSpecToScopeRequest({
        companyId: companyId,
        row: row,
        onSuccess: onAddSpecSuccess,
      })
    );
  };

  const onAddSpecSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    setStatusOfStepAgainstChange();
    makeAlreadyCompletedSubsequentStepsIncomplete();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId,
        pagination: { page: page, pageSize: PaginationSizeForFormTable },
        isInPomDistributor: isTableInPomDistributorQuestionnaire,
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: !isTableInPomDistributorQuestionnaire,
      })
    );
  };

  const makeAlreadyCompletedSubsequentStepsIncomplete = () => {
    //all Steps names
    const a2psStepName = PosEndUsersFormKeyMapping.A2psProcessControl;
    const bufferStockName = PomDistributorFormKeyMapping.BufferStockStrategy;
    const leadtimeProdStepName =
      PosEndUsersFormKeyMapping.LeadTimeForProduction;
    const leadTimeImplStepName =
      PosEndUsersFormKeyMapping.LeadTimeForImplementation;

    //all Steps
    const bufferStockStratStep = steps.find(
      (step) => step.step_name === bufferStockName
    );
    const leadTimeProdStep = steps.find(
      (step) => step.step_name === leadtimeProdStepName
    );
    const a2psStep = steps.find((step) => step.step_name === a2psStepName);
    const leadTimeImplStep = steps.find(
      (step) => step.step_name === leadTimeImplStepName
    );
    if (
      a2psStep?.is_completed &&
      scopeFormData?.has_captive_laboratory &&
      leadTimeImplStep?.is_completed
    ) {
      makeStepIncomplete(steps.indexOf(a2psStep));
      showIncompleteStepsMessageForScopeForm([
        a2psStepName,
        leadTimeImplStepName,
      ]);
      makeStepIncomplete(steps.indexOf(leadTimeImplStep));
      showIncompleteStepsMessageForScopeForm([leadTimeImplStepName]);
    } else if (
      a2psStep?.is_completed &&
      scopeFormData?.has_captive_laboratory
    ) {
      makeStepIncomplete(steps.indexOf(a2psStep));
      showIncompleteStepsMessageForScopeForm([a2psStepName]);
    } else if (
      leadTimeImplStep?.is_completed &&
      leadTimeProdStep?.is_completed
    ) {
      makeStepIncomplete(steps.indexOf(leadTimeImplStep));
      makeStepIncomplete(steps.indexOf(leadTimeProdStep));
      showIncompleteStepsMessageForScopeForm([
        leadTimeImplStepName,
        leadtimeProdStepName,
      ]);
    } else if (
      leadTimeImplStep?.is_completed &&
      bufferStockStratStep?.is_completed
    ) {
      makeStepIncomplete(steps.indexOf(leadTimeImplStep));
      makeStepIncomplete(steps.indexOf(bufferStockStratStep));
      showIncompleteStepsMessageForScopeForm([
        leadTimeImplStepName,
        bufferStockName,
      ]);
    } else if (leadTimeImplStep?.is_completed) {
      makeStepIncomplete(steps.indexOf(leadTimeImplStep));
      showIncompleteStepsMessageForScopeForm([leadTimeImplStepName]);
    } else if (leadTimeProdStep?.is_completed) {
      makeStepIncomplete(steps.indexOf(leadTimeProdStep));
      showIncompleteStepsMessageForScopeForm([leadtimeProdStepName]);
    } else if (bufferStockStratStep?.is_completed) {
      makeStepIncomplete(steps.indexOf(bufferStockStratStep));
      showIncompleteStepsMessageForScopeForm([bufferStockName]);
    }
  };

  const makeStepIncomplete = (stepIndex: number) => {
    if (stepIndex !== -1) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        stepIndex
      );
    }
  };

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = watch("additional_comment");
    dispatch(
      vendorManagementActions.saveAdditionalCommentFieldOfFormRequest({
        companyId: companyId,
        additionalComment: additionalComment,
        stepName: QuestionnaireForms.Scope,
      })
    );
    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(
      fieldOfStep,
      true,
      activeFormStepIndex
    );
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion = () => {
    const tableRows = specsOfScopeData?.results?.data || [];
    if (questionnaireName === Questionnaire.PomDistributor) {
      const isTableRowsValidated =
        specsOfScopeData.results.uncompleted_count === 0;
      if (isTableRowsValidated && tableRows.length > 0) {
        makeFormStepCompletionProcess();
      } else {
        setErrorAndShowInvalidMessage();
      }
    } else if (tableRows.length > 0) {
      makeFormStepCompletionProcess();
    } else {
      setErrorAndShowInvalidMessage();
    }
  };

  const setErrorAndShowInvalidMessage = () => {
    showInvalidMessage();
    if (!isShowTableError) {
      setIsShowTableError(true);
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompleted) {
      showThankYouModal();
    } else if (!isLastStep) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const setStatusOfStepAgainstChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
    } else {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_partially_filled",
        true,
        activeFormStepIndex
      );
    }
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle title={"Scope"} isCompleted={isFormCompleted} />

          <S.StyledBox>
            <S.StyledTableBox>
              <FormFieldLabel
                label={
                  questionnaireName === Questionnaire.PomDistributor
                    ? tableTopTextForPomDistributor
                    : tableTopTextForPosEndUserAndPomManufacturer
                }
              />

              <S.StyledRowBox>
                <SelectSpecType
                  specType={specType}
                  handleChange={handleSpecTypeChange}
                />

                <SearchTextField
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  clearSearch={clearSearch}
                />
              </S.StyledRowBox>

              {questionnaireName === Questionnaire.PomDistributor ? (
                <TableLinesBox
                  completedCount={specsOfScopeData?.results?.completed_count}
                  totalCount={specsOfScopeData?.results?.total_count}
                  hideCompletedTableLines={hideCompletedTableLines}
                  setHideCompletedTableLines={setHideCompletedTableLines}
                  setPage={setPage}
                />
              ) : null}

              <ScopeFormTable
                hideCompletedTableLines={hideCompletedTableLines}
                page={page}
                setPage={setPage}
                pageSize={PaginationSizeForFormTable}
              />
            </S.StyledTableBox>

            <S.StyledBox>
              <S.StyledInnerBox>
                <Box>
                  <FormAddButton
                    btnText={"Add specs"}
                    onClick={onClickOpenAddSpecListModal}
                  />
                </Box>

                <S.StyledTypography variant="body2">
                  {tableBottomText}
                </S.StyledTypography>
              </S.StyledInnerBox>

              {isShowTableError &&
              specsOfScopeData?.results?.data.length === 0 ? (
                <FormFieldErrorTypography text={FieldMandatoryText} />
              ) : null}
            </S.StyledBox>
          </S.StyledBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={scopeFormData?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={confirmStepCompletion}
        isCompleted={isFormCompleted}
      />

      <AddSpecListModal
        title={"Spec list"}
        rowsAlreadyAdded={specsOfScopeData?.results?.data || []}
        handleAddSpecToFormTable={handleAddSpecToScope}
      />
    </>
  );
};

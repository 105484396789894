import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FormStepBox } from "../form-step-box/form-step-box";
import { selectVisibleSteps } from "../../redux/selectors";
import { getActiveStepIndex } from "../../utils/getActiveStepIndex";
import * as S from "./custom-form-stepper.styled";

function ColorlibStepIcon(props: StepIconProps) {
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);

  const icons = steps.reduce<{ [index: string]: React.ReactElement }>(
    (acc, step, index) => {
      const isActive = activeFormStepIndex === index;
      acc[String(index + 1)] = (
        <FormStepBox
          name={step.step_name}
          isActive={isActive}
          isCompleted={step.is_completed}
          isPartiallyFilled={step.is_partially_filled}
          stepIndex={index}
        />
      );
      return acc;
    },
    {}
  );

  return <Box>{icons[String(props.icon)]}</Box>;
}

export const CustomFormStepper: React.FC = () => {
  const stepperRef = useRef<HTMLDivElement>(null);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);

  const handleScrollRight = () => {
    if (stepperRef.current) {
      stepperRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const handleScrollLeft = () => {
    if (stepperRef.current) {
      stepperRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  return (
    <S.StepperContainer>
      <S.StyledLeftIconButton onClick={handleScrollLeft}>
        <ChevronLeftIcon fontSize="small" />
      </S.StyledLeftIconButton>

      <S.StyledStepper
        ref={stepperRef}
        activeStep={activeFormStepIndex}
        connector={null}
        alternativeLabel={false}
      >
        {steps.map((step, index) => (
          <S.StyledStep key={step?.step_name} completed={step.is_completed}>
            <S.StyledStepLabel
              StepIconComponent={ColorlibStepIcon}
            ></S.StyledStepLabel>
          </S.StyledStep>
        ))}
      </S.StyledStepper>

      <S.StyledRightIconButton onClick={handleScrollRight}>
        <ChevronRightIcon fontSize="small" />
      </S.StyledRightIconButton>
    </S.StepperContainer>
  );
};

import React from "react";
import {
  GridCloseIcon,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import { HeaderCell } from "../table-cells/header-cell";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";
import { Box } from "@mui/material";
import { SelectSupplierAutoComplete } from "../../select-supplier-auto-complete/select-supplier-auto-complete";

const SourceOfSupplierCell: React.FC<GridRenderCellParams> = ({
  value,
  row,
}) => {
  return (
    <Box width="100%">
      {<SelectSupplierAutoComplete value={value} row={row} />}
    </Box>
  );
};

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getScopeFormTableColumns = (
  isTableInPomDistributorQuestionnaire: boolean
) => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "name",
      headerName: "Specification",
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
  ];

  const lastColumn = {
    ...defaultColumnConfig,
    field: "removeRowButton",
    headerName: " ",
    align: "center" as const,
    renderCell: (params: GridRenderCellParams) => (
      <IconButtonCell {...params} Icon={GridCloseIcon} />
    ),
    flex: 0.1,
  };

  const columnsForPomDistributor = [
    {
      ...defaultColumnConfig,
      field: "source_of_supply",
      headerName: "Source of supply",
      minWidth: 470,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <SourceOfSupplierCell {...params} />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "manufacturing_site",
      headerName: "Manufacturing site",
    },
    { ...lastColumn },
  ];

  if (isTableInPomDistributorQuestionnaire) {
    columns.push(...columnsForPomDistributor);
  } else {
    columns.push(lastColumn);
  }

  return columns;
};

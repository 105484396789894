import React from "react";
import * as S from "./form-field-label.styled";

type Props = {
  label: string;
};

export const FormFieldLabel: React.FC<Props> = ({ label }) => {
  return <S.StyledTypography variant="body1">{label}</S.StyledTypography>;
};

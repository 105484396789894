import querystring from "querystring";
import {
  APP_ENVIRONMENT,
  checkAppEnvironmentIs,
} from "@next/utils/constantUtils";

// Dynamic envs to override from the URL
export const dynamicEnvs = {
  REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  REACT_APP_BRP_RD_GATEWAY_COMPANY_ID:
    process.env.REACT_APP_BRP_RD_GATEWAY_COMPANY_ID,
  REACT_APP_RTAS_COMPANY_ID: process.env.REACT_APP_RTAS_COMPANY_ID,
  REACT_APP_SHOW_REDIRECT_MY_PARTS_TO_CATALOG_COMPANIES:
    process.env.REACT_APP_SHOW_REDIRECT_MY_PARTS_TO_CATALOG_COMPANIES,
  REACT_APP_SHOW_COMMISSION_FEE_COMPANIES:
    process.env.REACT_APP_SHOW_COMMISSION_FEE_COMPANIES,
  REACT_APP_MUI_DATA_GRID_PRO_LICENSE:
    process.env.REACT_APP_MUI_DATA_GRID_PRO_LICENSE,
  REACT_APP_MELOCHE_COMPANY_ID: process.env.REACT_APP_MELOCHE_COMPANY_ID,
  REACT_APP_MUI_DATA_GRID_PRO_LICENSE_V5:
    process.env.REACT_APP_MUI_DATA_GRID_PRO_LICENSE_V5,
  REACT_APP_AIRBUS_A220_PROGRAM_COMPANY_ID:
    process.env.REACT_APP_AIRBUS_A220_PROGRAM_COMPANY_ID,
};

const isProduction = checkAppEnvironmentIs([APP_ENVIRONMENT.PRODUCTION]);

if (!isProduction) {
  try {
    const queryParams = window.location.search.replace("?", "");
    const parsed = querystring.parse(queryParams);
    const envKeysToUpdateDynamically = Object.keys(dynamicEnvs);

    const setUpdateOrRemoveDynamicEnv = (key) => {
      let value = "";
      const valueFromLocalStorage = localStorage.getItem(key);
      if (valueFromLocalStorage) {
        value = valueFromLocalStorage;
      }
      const valueFromUrl = parsed[key];
      if (valueFromUrl) {
        if (valueFromUrl === "default") {
          localStorage.removeItem(key);
          value = process.env[key];
        } else {
          localStorage.setItem(key, valueFromUrl);
          value = valueFromUrl;
        }
      }

      if (value) {
        dynamicEnvs[key] = value;
      }
    };

    envKeysToUpdateDynamically.forEach((key) => {
      setUpdateOrRemoveDynamicEnv(key);
    });
  } catch (e) {
    console.error(e);
  }
}

const baseBackendUrl = dynamicEnvs.REACT_APP_BACKEND_URL;

export const backendUrl = {
  // Backend Location
  api: baseBackendUrl,
  announcements: baseBackendUrl + "announcements/",
  contactUs: baseBackendUrl + "contact-us",
  //upload pdf quote
  //Request For Information
  rfi: baseBackendUrl + "requestForInformation/",
  //Request For Quotation
  rfq: baseBackendUrl + "requestForQuotation/",
  quotes: baseBackendUrl + "requestForQuotation/?status=",
  requestForQuotation: baseBackendUrl + "requestForQuotation/",
  tenders: baseBackendUrl + "tenders/documents/",
  contracts: baseBackendUrl + "contracts",
  workspaceCadFiles: baseBackendUrl + "cadfiles",
  marketplace: baseBackendUrl + "marketplace/",
  marketplacePublic: baseBackendUrl + "marketplace/public/",
  marketplaceQuoteNoQuote: baseBackendUrl + "marketplace/quoteNoQuote/",
  addenda: baseBackendUrl + "rfq/addenda",
  //marketplace preferences
  marketplacePreferences: baseBackendUrl + "marketplace/prefs/",
  //marketplace recommend like dislike
  marketplaceLikeDislikeRecommend: baseBackendUrl + "recommendation/feedback/",
  // Quotation
  quotation: baseBackendUrl + "quotations",
  quotationAsBuyer: baseBackendUrl + "buyerQuote/",
  //customQuotations
  customQuotations: baseBackendUrl + "customQuotations",
  // list of Quotations
  quotations: baseBackendUrl + "quotations?status=",
  //upload pdf quote
  uploadQuotes: baseBackendUrl + "upload/quotes/",
  // Authentication
  register: baseBackendUrl + "rest-auth/registration/name-registration/",
  login: baseBackendUrl + "login/",
  loginCode: baseBackendUrl + "login/code/",
  logout: baseBackendUrl + "logout/",
  passwordRecovery: baseBackendUrl + "rest-auth/password/reset/",
  passwordRecoveryConfirmation:
    baseBackendUrl + "profile/resend-email-confirm/",
  passwordReset: baseBackendUrl + "rest-auth/password/reset/confirm/",
  refreshToken: baseBackendUrl + "api/refresh/",
  ott: baseBackendUrl + "auth/createOTT/",
  generatePortalUrl: baseBackendUrl + "generate-portal-url/",

  //workspace
  parts: baseBackendUrl + "parts/",
  part: baseBackendUrl + "part/",
  uploadPart: baseBackendUrl + "upload/part/",
  uploadRfqAttachment: baseBackendUrl + "requestForQuotation/attachments/",
  favoriteSuppliers: baseBackendUrl + "favoriteSuppliers/",
  favoriteSupplierGroups: baseBackendUrl + "favoriteSuppliers/groups/",

  //part Configuration
  partConfiguration: baseBackendUrl + "configuration/",

  // Profile Management
  profile: baseBackendUrl + "profile/",
  profileV2: baseBackendUrl + "my-profile/",
  changeUserPassword: baseBackendUrl + "rest-auth/password/change/",
  inviteMember: baseBackendUrl + "profile/invite/",
  changeUserLanguage: baseBackendUrl + "profile/activate-language/",
  sendAdminMessage: baseBackendUrl + "profile/contact-us/",
  profilePreferences: baseBackendUrl + "profile/preferences/",
  userPermissions: baseBackendUrl + "profile/permissions/",
  emailExist: baseBackendUrl + "profile/checkEmail/",
  authUrl: baseBackendUrl + "auth/",
  userActiveMethods: baseBackendUrl + "auth/mfa/user-active-methods/",

  // Company Management
  company: baseBackendUrl + "companies",
  fetchUserCompany: baseBackendUrl + "company/user/",
  rateCompany: baseBackendUrl + "company/rating/",
  createProcess: baseBackendUrl + "company/process/",
  createStandard: baseBackendUrl + "company/standard/",
  emailSubscribed: baseBackendUrl + "is-email-subscribed/",
  userNotification: baseBackendUrl,

  // Marketplace management
  market: baseBackendUrl + "marketplace/",

  // Quote Management
  quote: baseBackendUrl + "quote/",
  fetchOwnedQuote: baseBackendUrl + "quote/open/",
  quoteMarketplace: baseBackendUrl + "quote/marketplace/",

  // Quote Contracts
  wonContracts: baseBackendUrl + "quote/contract/",

  // Bids Management
  bid: baseBackendUrl + "bid/",
  fetchQuoteBid: baseBackendUrl + "bid/quote/",
  selectWinningBid: baseBackendUrl + "bid/set-winner/",
  fetchOwnedBids: baseBackendUrl + "bid/open/",
  uploadPdfFile: baseBackendUrl + "bid/file/pdf/",

  // File Management
  cadFile: baseBackendUrl + "quote/file/cad/",
  drawingFile: baseBackendUrl + "quote/file/drawing/",
  ndaFile: baseBackendUrl + "quote/file/nda/",
  workspace: baseBackendUrl + "workspace/",
  workspaceUpload: baseBackendUrl + "workspace/upload/",
  presignedS3: baseBackendUrl + "storage/presignedUrl",
  finishMultipart: baseBackendUrl + "storage/finishMultipartUpload",

  //Bid Creation V2
  createBids: baseBackendUrl + "bid_array/",

  //tracking
  tracking: baseBackendUrl + "scout",
  //Subscription
  subscriptions: baseBackendUrl + "subscriptions/",

  //Company Profile
  companyProfile: baseBackendUrl + "companies",
  companyPublicProfile: baseBackendUrl + "supplierProfile",

  //Subscription
  userSubscriptions: baseBackendUrl + "userSubscriptions/",
  //Upload rfq purchase order
  uploadRfqPo: baseBackendUrl + "upload/rfqPo/",

  fetchAddress: baseBackendUrl + "locate/",

  fetchPostalCode: baseBackendUrl + "searchPostalCode/",

  NDA: baseBackendUrl + "requestForQuotation/nda/",
  tags: baseBackendUrl + "tags/",
  buyerStats: baseBackendUrl + "rfq",
  inbox: baseBackendUrl + "inbox/",
  inboxFileUpload: baseBackendUrl + "inbox/attachments/",
  // company ratings
  companyRatings: baseBackendUrl + "company-ratings/",
  ratingInfo: baseBackendUrl + "rating-info/",
  sendRating: baseBackendUrl + "ratings/",

  // NPS
  nps: baseBackendUrl + "nps/",

  registerUserOnBoarding: baseBackendUrl + "register/",
  uploadCompanyImage: baseBackendUrl + "upload/company-image/",

  // request a demo
  requestDemo: baseBackendUrl + "request-demo/",
  requestRating: baseBackendUrl + "request-rating/",
  //company capabilities
  companyCapabilities: baseBackendUrl + "company-capabilities/",
  companyCapabilitiesTypes: baseBackendUrl + "capabilityTypes/",
  projectsAwarded: baseBackendUrl + "projects/awarded/info",
  projects: baseBackendUrl + "projects",
  project: baseBackendUrl + "project",
  intentToNoQuote: baseBackendUrl,
  companyFileReferences: baseBackendUrl + "company/fileReferences",
  companyFileDocuments: baseBackendUrl + "company/fileDocuments",

  rfqs: baseBackendUrl + "rfqs",
  rfqDrafts: baseBackendUrl + "rfqs/drafts",
  draft: baseBackendUrl + "draft",

  //QA questions
  questions: "questions/",
  questionsBase: baseBackendUrl + "questions/",
  inviteSupplier: baseBackendUrl + "company/inviteSuppliers/",
  searchSupplier: baseBackendUrl + "company/search",
  addASL: baseBackendUrl + "company/asl/add",
  rfqQuestions: baseBackendUrl + "rfqs/questions/",
  qa: baseBackendUrl + "qa/",

  //favorite suppliers
  favoriteSuppliersSearch: baseBackendUrl + "favoriteSuppliers/search/",

  // discord notification
  discordNotify: baseBackendUrl + "discord/notify/",

  bomExport: baseBackendUrl + "projects/bomExport/",
  bomImport: baseBackendUrl + "projects/bomImport/",

  //Portal
  supplierPortal: baseBackendUrl + "supplierPortal/",

  //project templates
  projectTemplates: baseBackendUrl + "projectTemplates/",

  //orders
  orders: baseBackendUrl + "orders",

  //internal notes
  notes: baseBackendUrl + "internalNotes/",

  // supplier orders
  supplierLineItems: baseBackendUrl + "orders/supplierLineItems",

  //orders
  messageNotifications: baseBackendUrl + "message-notifications/",

  //global search
  globalSearch: baseBackendUrl + "globalSearch/",

  // Company Profile analytics
  companyProfileAnalytics: baseBackendUrl + "companyProfileAnalytics/stats",

  // Company Profile Views
  companyProfileViews: baseBackendUrl + "companyProfileViews",

  // Premium suppliers onboarding status
  supplierOnboardingStatus: baseBackendUrl + "suplierOnboardingStatus",

  // Company discovery
  discovery: baseBackendUrl + "discovery",

  // Buyer discovery
  buyerDiscovery: baseBackendUrl + "buyersDiscovery",

  // Supplier stats
  axyaStatsSupplier: baseBackendUrl + "axya-stats",

  // Check user email
  checkUserEmail: baseBackendUrl + "checkUserEmail/",

  // Newsfeed
  newsfeed: baseBackendUrl + "newsfeed",

  supplierConnections: baseBackendUrl + "connections",

  quoteComparisonConfigs: baseBackendUrl + "quoteComparisonConfigs",

  easyRFQ: baseBackendUrl + "easyRFQ",

  //Analytics
  analyticsRfqs: baseBackendUrl + "analytics/rfqs",
  analyticsOrders: baseBackendUrl + "analytics/purchaseOrders",

  syncERPQuotation: baseBackendUrl + "erp/quotationSync",

  // Custom urls for companies
  airbusConfirmation: baseBackendUrl + "airbus/confirm/",

  // Airbus Vendor Management
  airbusVendorManagementBaseUrl: baseBackendUrl + "airbus",
  airbusVendorManagementUrls: {
    identificationForm: baseBackendUrl + "airbus/companies",
    approvalForm: baseBackendUrl + "airbus/approvals/list-by-company",
    onboardingForm: baseBackendUrl + "airbus/supplier-access",
    scopeForm: baseBackendUrl + "airbus/scopes",
    a2psProcessControlForm: baseBackendUrl + "airbus/process-controls",
    leadTimeForImplementationForm:
      baseBackendUrl + "airbus/lead-time-implementations",
    leadTimeForProductionForm: baseBackendUrl + "airbus/lead-time-production",
    designAndBuiltForm: baseBackendUrl + "airbus/design-and-built",
    subtiersForm: baseBackendUrl + "airbus/subtiers",
    subtiersOnboardingForm: baseBackendUrl + "airbus/subtier-onboarding",
    bufferStockStrategyForm: baseBackendUrl + "airbus/buffer-stock",
  },
};

// Frontend Urls
export const frontendUrl = {
  // Static Pages
  home: "/",
  about: "/about",
  notFound: "/notFound",
  notAuthorized: "/notAuthorized",
  onePager: "/onepager",
  requestDemo: "/get-an-instant-demo",

  // myParts url
  myParts: "/my-parts",

  // catalog url
  catalog: "/catalog",

  // redirect to the list of orders
  orders: "/orders",

  // redirect to the list of supplier orders
  supplierLineItems: "/supplierLineItems",

  // redirect to the list of bundle quotes  for a buyer
  buyerQuotes: "/requests",

  // poc: "/poc",

  // The projects base URL
  projects: "/projects",

  // The Axya Pay base URL
  axyapay: "/axyapay",

  // redirect to the  bundle quote  for a buyer
  bundleView: "/requests/view",

  // redirect to the CongratsView
  congratsView: "/workspace/congrats",

  //Market place url
  marketplace: "/marketplace",

  //Rfq url
  requestForQuotations: "/marketplace/rfq",

  // Redirect to list of contracts for a supplier
  supplierContracts: "/supplier-contracts",

  supplierContractView: "/supplier-contracts/view",

  // Redirect to  bids for a supplier
  supplierQuotes: "/supplier-quotes",

  // Redirect to list of submitted bid for a supplier
  supplierQuoteView: "/supplier-quotes/view",

  //revise quotation
  reviseQuotation: "/revise/quotation",
  // trash url
  trash: "/marketplace/trash",

  // Authentication
  auth: "/auth",
  keycloakAuthCallback: "/auth-callback",
  login: "/auth/login",
  loginWithRedirection: `/auth/login?redirect=${window.location.pathname}${window.location.search}`,
  login2FA: "/auth/login2FA",
  unsubscribed: "/auth/unsubscribed",
  logout: "/auth/logout",
  register: "/auth/register",
  authIssues: "/auth/recover",
  // registerSupplierCompany: "/auth/register-supplier-company",
  // registerBuyerCompany: "/auth/register-buyer-company",
  validationEmailSent: "/auth/validation-email-sent",
  linkExpired: "/auth/link-expired",
  emailSent: "/auth/email-sent",
  actualResetPassword: "/auth/password-reset/:uid/:token/",
  //answer question view
  answerQuestionView: "/qa",

  // portal view
  portal: "/portal",

  userRatingView: "/rating",

  // access denied
  accessDenied: "/access-denied",

  // App Specific urls

  // Settings
  settings: "/settings",

  // User Profile Management
  userProfile: "/profile",
  editUserProfile: "/profile/edit",
  changeUserPassword: "/profile/change-password",
  changeUserLanguage: "/profile/change-language",
  isEmailSubscrib: "/profile/email-subscription",

  // Company Profile Management
  companyProfile: "/company",
  joinOrRegisterCompany: "/registration",
  registerCompany: "/registration/create",
  joinCompany: "/registration/join",
  createCompany: "/company/create",
  editCompany: "/company/edit",
  createQuote: "/company/create-quote",
  contracts: "/company/contracts",
  contractDetail: "/company/contract/detail",
  inviteMember: "/company/send-invite",
  followUp: "/company/follow-up",
  editBid: "/company/follow-up/edit-bid",
  quoteViewDo: "/company/follow-up/quote",
  companySetting: "/company/setting",
  buyerProfile: "/company/buyer-profile",
  publicRequest: "/public-request",

  // Market Management
  market: "/market",
  quoteViewFab: "/market/quote",
  editQuotation: "/edit-quotation",
  mySuppliers: "/my-suppliers",
  suppliersDirectory: "/suppliers-directory",

  // Dashboard
  dashboard: "/dashboard",

  // Analytics
  analytics: "/analytics",

  // Newsfeed
  newsfeed: "/newsfeed",

  // Vendor Management
  publicVendorManagementPosEndUsersQuestionnaire:
    "/airbus/questionnaire-end-users-aerostructure-detail-parts",
  publicVendorManagementPomManufacturerQuestionnaire:
    "/airbus/questionnaire-manufacturer",
  publicVendorManagementPomDistributorQuestionnaire:
    "/airbus/questionnaire-distributors",

  publicRfqOLD: "/public-rfq",

  publicRfqAmq: "/public-rfq-amq",

  publicRfqGeneral: "/public-rfq-general",

  publicItbItnb: "/public-itb-itnb",

  publicPreferences: "/public-preferences",

  // Custom urls for companies
  airbusProcurement: "/airbus_procurement",
};

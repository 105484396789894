import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Link } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormBoxMd } from "../../form-box/form-box-md";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormTitle } from "../../form-title/form-title";
import { FormFileAttachmentButton } from "../../form-file-attachment-button/form-file-attachment-button";
import { FormAttachedFileBox } from "../../form-attached-file-box/form-attached-file-box";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import {
  FieldMandatoryText,
  FileObj,
  PartialStep,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectFormData,
  selectIsAllStepsCompleted,
  selectIsFormCompleted,
  selectIsLastStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { modalsActions } from "@next/redux/modalsSlices";
import { getSafeFieldNameForErrorHook } from "@next/modules/vendor-management/utils/getSafeFieldNameForErrorHook";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import * as S from "./approval-form.styled";

const link = "https://supplier.aero.bombardier.com/A220-SQA/";
const linklabel = "https://supplier.aero.bombardier.com/A220-SQA/";

export const ApprovalForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const fileInputRefs = useRef<{
    [key: string]: React.MutableRefObject<HTMLInputElement | null>;
  }>({});
  const formData = useSelector(selectFormData) as FileObj[];

  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<{ [key: string]: FileObj }>({
    defaultValues:
      formData && formData?.length > 0
        ? formData?.reduce(
            (acc, fileObj) => ({
              ...acc,
              [fileObj.field_name]: fileObj,
            }),
            {}
          )
        : [],
  });

  const handleAttachButtonClick = (fieldName: string) => {
    const inputRef = fileInputRefs.current[fieldName];
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fileObj: FileObj
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      dispatch(
        vendorManagementActions.updateApprovalFormFileRequest({
          companyId: companyId,
          field_name: fileObj.field_name,
          field_title: fileObj.field_title,
          file: selectedFile,
        })
      );
      checkInputChange();

      const safeFieldNameForError = getSafeFieldNameForErrorHook(
        fileObj.field_name
      );
      if (errors[safeFieldNameForError]) {
        clearErrors(safeFieldNameForError);
      }
    }
  };

  const handleClickRemove = (fileObj: FileObj) => {
    dispatch(
      vendorManagementActions.deleteApprovalFormFileRequest(fileObj?.file_id)
    );
    checkInputChange();
  };

  const validateFilesAndSetError = () => {
    let isValid = true;
    formData.forEach((fileObj) => {
      if (!fileObj.file_url) {
        const safeFieldNameForError = getSafeFieldNameForErrorHook(
          fileObj.field_name
        );
        setError(safeFieldNameForError, { type: "manual" });
        isValid = false;
      }
    });
    return isValid;
  };

  const saveChanges = (isForCompletion: boolean) => {
    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(
      fieldOfStep,
      true,
      activeFormStepIndex
    );
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion = () => {
    const isFormValidated = validateFilesAndSetError();

    if (isFormValidated) {
      makeFormStepCompletionProcess();
    } else {
      showInvalidMessage();
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompleted) {
      showThankYouModal();
    } else if (!isLastStep) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
    } else {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_partially_filled",
        true,
        activeFormStepIndex
      );
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const renderFileField = (fileObj: FileObj) => {
    if (!fileInputRefs.current[fileObj.field_name]) {
      fileInputRefs.current[fileObj.field_name] =
        React.createRef<HTMLInputElement>();
    }
    const safeFieldNameForError = getSafeFieldNameForErrorHook(
      fileObj.field_name
    );

    return (
      <FormFieldBox key={fileObj.field_name}>
        <S.StyledTypography>{fileObj.field_title}</S.StyledTypography>
        <Box>
          <FormFileAttachmentButton
            handleAttachButtonClick={() =>
              handleAttachButtonClick(fileObj.field_name)
            }
            handleFileChange={(event) => handleFileChange(event, fileObj)}
            fileInputRef={fileInputRefs.current[fileObj.field_name]}
            btnText={"Attach"}
          />
        </Box>
        {fileObj.file_url ? (
          <S.AttachmentBox>
            <FormAttachedFileBox
              file={fileObj}
              handleClickRemove={() => handleClickRemove(fileObj)}
            />
          </S.AttachmentBox>
        ) : null}
        {errors[safeFieldNameForError] && (
          <Box mt={"-5px"}>
            <FormFieldErrorTypography text={FieldMandatoryText} />
          </Box>
        )}
      </FormFieldBox>
    );
  };

  return (
    <>
      <FormParentBox>
        <FormBoxMd isCompleted={isFormCompleted}>
          <FormTitle title={"Approval"} isCompleted={isFormCompleted} />

          <S.StyledTextBox>
            <S.StyledTypography variant="body1">
              Your Company has not yet been approved by Airbus Canada & is thus
              not part of A220 ASL (Approved Supplier List).
            </S.StyledTypography>
            <S.StyledTypography variant="body1">
              So as to launch the approval process please attached mandatory
              evidences listed below.
            </S.StyledTypography>
          </S.StyledTextBox>

          <S.StyledTypography variant="body1">
            *QMSF templates available on{" "}
            <Link
              href={link}
              target="_blank"
              rel="noopener"
              variant="body1"
              underline="none"
            >
              {linklabel}
            </Link>
          </S.StyledTypography>

          {formData && formData?.length > 0
            ? formData?.map((fileObj) => renderFileField(fileObj))
            : null}
        </FormBoxMd>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={confirmStepCompletion}
        isCompleted={isFormCompleted}
      />
    </>
  );
};
